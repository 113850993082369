<template>
  <section>
    <!-- <HeaderSection :title="$t('common.stores')" /> -->
    <Closemobile
      v-if="state.isMobileDrawer"
      @click="state.isMobileDrawer = !state.isMobileDrawer"
      :class="state.isMobileDrawer ? 'z-[10002]' : ''"
      class="absolute w-10 h-10 right-2 closemobile md:mt-10"
    />
    <div
      class="relative flex lg:h-full w-full flex-col-reverse lg:flex-row pt-16 lg:pt-[84px] h-screen lg:w-full"
    >
      <div
        v-if="state.isMobileDrawer"
        class="absolute inset-0 bg-black bg-opacity-50"
        :class="state.isMobileDrawer ? 'z-[10000]' : ''"
        @click="state.isMobileDrawer = !state.isMobileDrawer"
      />
      <div
        :class="state.isMobileDrawer ? 'active z-[10002]' : 'z-10'"
        class="relative transition-[height] -mt-2 lg:mt-0 duration-200 bg-white h-full bottom-0 lg:pt-8 display-sidebar overflow-hidden lg:z-10 left-0 lg:border md:border-white lg:pb-11 rounded-t-2xl lg:rounded-none md:w-full right-0 pb-8 lg:w-[364px] xl:w-[446px]"
      >
        <!-- Drawer toggle icon -->
        <div
          class="flex justify-center cursor-pointer lg:hidden mt-2.5 mb-[26px]"
          @click="onToggleDrawer"
        >
          <!-- <img src="@/assets/svg/drawer-icon.svg" alt=""> -->
          <IconsDrawer />
        </div>
        <div
          class="grid border-b lg:px-6 xl:px-10 relative z-10 border-b-[#EEE] pb-3.5 grid-cols-1 px-4 lg:mt-0 lg:inline-block lg:w-full md:space-y-0 md:gap-6 md:grid-cols-2 lg:gap-0 lg:grid-cols md:px-8"
          :class="isScrollActive ? 'shadow-md' : ''"
        >
          <!-- Search input -->
          <div class="relative block">
            <span
              class="absolute inset-y-0 flex items-center ltr:left-0 rtl:right-0 ltr:pl-4 rtl:pr-4"
            >
              <Search2LineIcon class="w-5 h-5" />
            </span>

            <input
              id="autocompleteStart"
              ref="autocompleteInput"
              class="block w-full ltr:pl-10 rtl:pl-20 ltr:pr-20 rtl:pr-10 pt-2.5 lg:py-[14px] bg-white border rounded-xl shadow-sm placeholder:italic placeholder:font-myriad-proira placeholder:font-normal placeholder:text-slate-400 border-slate-300 focus:outline-none focus:border-[#307CFF] focus:ring-primary focus:ring-1 font-normal !leading-6 text-sm"
              placeholder="Search"
              :class="isEmpty ? 'border-primary-600' : 'border-[#D9D9D9]'"
              type="text"
              name="search"
              @focus="onOpenDrawer"
              @input="onSearch"
              v-model="searchQuery"
            />
            <div
              class="absolute transform -translate-y-1/2 right-16 top-1/2"
              v-if="loading || isLoading"
            >
              <Spinner class="text-black" />
            </div>
            <div
              v-if="storeAddress.length > 0 && isSuggestionsOpen"
              class="absolute w-full max-h-72 ] font-myriad-proira z-[999999] overflow-y-auto scrollbar-thtext-[#000000umb bg-white border border-gray-300 border-t-0 shadow-lg mt-1 rounded-md z-10"
            >
              <ul v-click-away="closeSuggestions">
                <li
                  v-for="address in storeAddress"
                  :key="address.id"
                  @click="selectAddress(address)"
                  class="p-3 cursor-pointer hover:bg-gray-100 z-[999999] border-b border-gray-300"
                >
                  <p class="font-semibold text-[16px]">
                    {{ address.company_name }}
                  </p>
                  <p class="text-sm text-black-400" v-html="address.text"></p>
                </li>
              </ul>
            </div>

            <div
              class="absolute text-gray-500 bg-white border-0 top-2.5 bottom-2.5 end-12"
            >
              <span class="border-l border-[#D9D9D9] h-full inline" />
            </div>
            <client-only>
              <span
                v-click-away="away"
                @keydown.escape="isDropdownOpen = false"
              >
                <button
                  class="absolute transition-all ease-in-out transform -translate-y-1/2 bg-white border-0 end-4 top-1/2 hover:animate-pulse"
                  @click.prevent="onSwitchDropdown"
                >
                  <EqualizerLineIcon class="w-[18px] h-[18px]" />
                </button>
                <div v-show="isDropdownOpen" class="relative">
                  <div class="absolute z-50 ltr:right-0 rtl:left-0 top-2">
                    <div
                      class="bg-[#FFFFFF] border border-gray-200 rounded-[10px] px-5 py-[14px]"
                      style="
                        box-shadow: 0px 10px 30px rgba(147, 148, 151, 0.36);
                      "
                    >
                      <ul
                        class="flex flex-row pl-0 mt-0 mb-4 list-none border-b-0"
                      >
                        <!-- GMail link -->
                        <li>
                          <span
                            class="block px-3 py-2 text-sm font-medium no-underline uppercase border-b border-primary"
                            :class="[
                              activetab === 'km'
                                ? 'text-primary rounded border-b-4 border-primary cursor-default'
                                : 'cursor-pointer no-underline border-b border-[#EAEAEA] ',
                            ]"
                            @click="activeTabMethod('km')"
                          >
                            KM
                          </span>
                        </li>
                        <!-- Apple Mail link -->
                        <li>
                          <span
                            class="block px-3 py-2 text-sm font-medium no-underline uppercase border-b border-primary"
                            :class="[
                              activetab === 'ml'
                                ? 'text-primary rounded border-b-4 border-primary cursor-default'
                                : 'cursor-pointer no-underline border-b border-[#EAEAEA] ',
                            ]"
                            @click="activeTabMethod('ml')"
                          >
                            ML
                          </span>
                        </li>
                      </ul>

                      <ul class="mt-5 ml-2">
                        <li>
                          <div class="flex items-center mr-4">
                            <input
                              id="50"
                              v-model="distance"
                              type="radio"
                              value="50"
                              class="w-4 h-4 bg-white border-black text-primary focus:ring-2"
                              @change="
                                searchPlaceWithFill({
                                  use_distance: searchQuery.length > 0,
                                })
                              "
                            />
                            <label
                              for="50"
                              class="font-normal text-black ltr:ml-2 rtl:mr-2 text-md dark:text-black"
                              >50</label
                            >
                          </div>
                        </li>
                        <li class="my-3">
                          <div class="flex items-center mr-4">
                            <input
                              id="25"
                              v-model="distance"
                              type="radio"
                              value="25"
                              class="w-4 h-4 bg-white border-black text-primary focus:ring-2"
                              @change="
                                searchPlaceWithFill({
                                  use_distance: searchQuery.length > 0,
                                })
                              "
                            />
                            <label
                              for="25"
                              class="font-normal text-black ltr:ml-2 rtl:mr-2 text-md dark:text-black"
                              >25</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mr-4">
                            <input
                              id="15"
                              v-model="distance"
                              type="radio"
                              value="15"
                              class="w-4 h-4 bg-white border-black text-primary focus:ring-2"
                              @change="
                                searchPlaceWithFill({
                                  use_distance: searchQuery.length > 0,
                                })
                              "
                            />
                            <label
                              for="15"
                              class="font-normal text-black ltr:ml-2 rtl:mr-2 text-md dark:text-black"
                              >15</label
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </span>
            </client-only>
          </div>

          <!-- Tabs -->
          <div class="pt-3.5 pb-3 md:py-0 lg:pt-[18px]">
            <div class="relative">
              <BaseTab :tabs="tabs" @activeTab="storeInfoCurrentTab" />
            </div>
          </div>
        </div>

        <!-- Store Cards Info -->
        <div
          v-if="!isLoading"
          @scroll="onScroll"
          class="items-start lg:px-6 xl:px-10 justify-start w-full h-full px-4 gap-3 md:px-8 overscroll-none py-4 md:pb-7.5 lg:pb-0 md:pt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 no-scrollbar card-scrollebar overflow-y-scroll md:gap-4"
        >
          <div
            v-for="(storev, index) in stores"
            :key="index"
            class="min-w-[266px] md:min-w-full"
            @click="goToMarker(index)"
          >
            <TabStoreInfoCards
              :distance="storev.distance"
              :info="storev.info"
              :show-detail="selectedStore === index"
              :position="storev.position"
            />
          </div>
        </div>
        <div
          v-if="isLoading"
          class="grid h-full grid-cols-1 gap-4 mt-4 overflow-y-scroll no-scrollbar lg:grid-cols-1 md:grid-cols-2 md:px-10"
        >
          <div v-for="index in new Array(4)" :key="index">
            <ContentLoader
              class="h-auto w-full md:w-[340px] lg:w-[290px] xl:w-[360px] md:px-0 rounded-xl overflow-hidden"
              height="300"
              :speed="2"
              view-box="0 0 400 300"
            >
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="300" />
            </ContentLoader>
          </div>
        </div>
        <div
          v-else-if="!stores.length && !isLoading"
          class="flex flex-col items-center justify-center w-full mt-4"
        >
          <IconsEmpty />
          <p class="mt-4 empty-state-title">
            {{ $t("common.no_available_stores_at_the_momemt") }}
          </p>
        </div>
      </div>

      <!-- Map Tabs -->
      <div
        class="relative z-0 flex-1 h-full overflow-hidden md:w-full md:h-auto"
      >
        <div
          class="absolute inset-x-0 z-10 flex items-center w-auto px-5 overflow-x-scroll no-scrollbar top-8"
        >
          <button
            v-for="(s, si) in servicesList"
            :key="si"
            class="inline-block mb-2 cursor-pointer me-2"
            @click.prevent="toggleService(s)"
          >
            <span
              :class="[
                services.indexOf(s) !== -1
                  ? 'bg-[#2C3032] text-white'
                  : 'bg-white text-black',
                'flex rounded-[50px] items-center justify-center min-w-44 py-2 px-4 md:px-[22px] text-sm font-medium capitalize',
              ]"
            >
              <span
                class="w-auto whitespace-nowrap"
                v-text="$t(`common.${s}`)"
              />
            </span>
          </button>
        </div>
        <div id="map" ref="mapUi" class="w-full h-full pb-3 xl:w-full" />
      </div>
    </div>
  </section>
</template>
<script setup>
import { ContentLoader } from "vue-content-loader";
import debounce from "lodash-es/debounce";
import { createApp, render, createVNode } from "vue";
import Search2LineIcon from "~/assets/svg/heroicons/search-2-line.svg?component";
import Closemobile from "~/assets/svg/closemobile.svg?component";
import EqualizerLineIcon from "~/assets/svg/heroicons/equalizer-line-new.svg?component";
import StoreInfoWindow from "~/components/StoreInfoWindow";
import { useTranslation } from "i18next-vue";
import { useMainStore } from "@/store/index";

const mainStore = useMainStore();
const route = useRoute();
const { t } = useTranslation();
const nuxtApp = useNuxtApp();

const mapUi = ref(null);
const isScrollActive = ref(false);
const storeAddress = ref([]);
const googleMap = ref(null);
let map = reactive(null);
const isMobile = ref(false);
const autocomplete = ref(null);
const bounds = ref(null);
const markerClustererData = ref(null);
// search
const state = reactive({
  isMobileDrawer: false,
  distributorCategories: [],
});
let place = reactive({
  lat: null,
  lng: null,
});
const isEmpty = ref(false);
const isSuggestionsOpen = ref(false);
const isSearchable = ref(false);
const searchQuery = ref("");
const distance = ref(50);
const distanceUnit = ref("km");
const services = ref([]);
const stores = ref([]);
const selectedStore = ref(null);
const markers = ref([]);
const isLoading = ref(true);
const isFetching = ref(false);
const infoWindows = ref([]);
const tabs = ref([t("common.all"), t("common.store"), t("common.service")]);
const isDropdownOpen = ref(false);
const activetab = ref("km");
const servicesList = ref([]);
const autocompleteInput = ref(null);
const storeInfoPopup = ref(null);
let MarkerClusterer = null;
const loading = ref(false);

const API_URL = "/front/stores/v2/suggestions"; // Update with the correct endpoint

// Fetch address suggestions from the API

async function fetchSuggestions(searchQuery) {
  if (!searchQuery) {
    storeAddress.value = [];
    isEmpty.value = true;
    loading.value = false;
    return;
  }

  try {
    // Make the API call using nuxtApp.$api
    const response = await nuxtApp.$api("/api/front/stores/v2/suggestions", {
      method: "POST",
      query: {
        address: searchQuery, // Pass the search query to the API
      },
    });

    if (response) {
      loading.value = false;
      storeAddress.value = response.suggestions;
      isEmpty.value = false;

      isSuggestionsOpen.value = true; // Show suggestions when there are results
    } else {
      storeAddress.value = [];
      loading.value = false;
      isEmpty.value = true;
      isSuggestionsOpen.value = false;
    }
  } catch (error) {
    console.error("Error fetching address suggestions:", error);
    storeAddress.value = [];
    isEmpty.value = true;
    isSuggestionsOpen.value = false;
    loading.value = false;
  }
}
const onScroll = (e) => {
  isScrollActive.value = e.target.scrollTop > 0;
};
const onSearch = debounce(async (e) => {
  // Perform search only if the search query has 3 or more characters
  if (searchQuery.value.length >= 2) {
    loading.value = true;
    await fetchSuggestions(searchQuery.value);
  } else if (
    (searchQuery.value === "" || searchQuery.value.length <= 1) &&
    !isSearchable.value
  ) {
    storeAddress.value = [];
    isSuggestionsOpen.value = false;
    isEmpty.value = false;
  } else {
    storeAddress.value = []; // Clear suggestions if the input is less than 3 characters
    isSuggestionsOpen.value = false;
    isEmpty.value = false;
    isSearchable.value = false;
    setCompanyCenter();
  }
}, 650);

onMounted(async () => {
  if (!import.meta.browser) {
    return;
  }
  if (!import.meta.client) {
    return;
  }
  MarkerClusterer = await import("@googlemaps/markerclusterer");

  if (window.innerWidth >= 1023) {
    state.isMobileDrawer = false;
    isMobile.value = false;
  } else {
    isMobile.value = true;
  }

  nuxtApp.$loadGoogleMaps().then(async () => {
    // Bind Map
    map = new window.google.maps.Map(mapUi.value, {
      mapId: "bb21f8f7be75a8ab",
      center: {
        lat: mainStore.currentSite.country.country_lat
          ? parseFloat(mainStore.currentSite.country.country_lat)
          : parseFloat("43.112334"),
        lng: mainStore.currentSite.country.country_long
          ? parseFloat(mainStore.currentSite.country.country_long)
          : parseFloat("12.594401"),
      },
      maxZoom: 22,
      zoom: 5,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        position: window.google.maps.ControlPosition.BOTTOM_RIGHT,
        mapTypeIds: [
          window.google.maps.MapTypeId.ROADMAP,
          window.google.maps.MapTypeId.SATELLITE,
        ],
      },
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControlOptions: {
        position: window.google.maps.ControlPosition.BOTTOM_RIGHT,
      },
    });

    // map.setPadding(400, 100, 100, 100)

    let restriction = {};

    if (mainStore.country !== "int") {
      restriction = {
        componentRestrictions: {
          country: mainStore.country,
        },
      };
    }

    addGeoLocationButton();
    bounds.value = new window.google.maps.LatLngBounds();

    await nextTick();
    setTimeout(() => {
      updateIcons();
    }, 2500);

    window.onresize = reportWindowSize;
    // setCompanyCenter();
    getCurrentPosition(true);
  });
});
function selectAddress(address) {
  place = {
    lat: address.latitude,
    lng: address.longitude,
  };
  isSearchable.value = true;
  searchQuery.value = stripHtml(address.text);
  searchPlace();

  // Optionally, you can also update the search query or do anything else with the selected address

  isSuggestionsOpen.value = false;
}
function stripHtml(html) {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}
function onToggleDrawer() {
  state.isMobileDrawer = !state.isMobileDrawer;
}
function onOpenDrawer() {
  if (isMobile.value) {
    state.isMobileDrawer = true;
  }
}
function onSwitchDropdown() {
  isDropdownOpen.value = !isDropdownOpen.value;
  if (isMobile.value) {
    state.isMobileDrawer = true;
  }
}

function searchPlaceWithFill(data) {
  if (!searchQuery.value) {
    isEmpty.value = true;
    return;
  }

  isEmpty.value = false;
  searchPlace(data);
}
function away() {
  isEmpty.value = false;
  isDropdownOpen.value = false;
}
function addGeoLocationButton() {
  const locationButton = document.createElement("button");

  locationButton.textContent = "";
  locationButton.classList.add("custom-map-control-button");
  map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
    locationButton
  );
  locationButton.addEventListener("click", () => {
    // Try HTML5 geolocation.
    getCurrentPosition();
  });
}
function handleLocationError(browserHasGeolocation, infoWindow, pos) {
  infoWindow.setPosition(pos);
  infoWindow.setContent(
    browserHasGeolocation
      ? t("common.error_geolocation_service")
      : t("common.error_geolocation_not_support")
  );
  infoWindow.open(map);
}
function storeInfoCurrentTab(tab) {
  if (tab === "Store") {
    state.distributorCategories = ["point_of_sale"];
  } else if (tab === "Service") {
    state.distributorCategories = ["service_center"];
  } else if (tab === "All") {
    state.distributorCategories = [];
  }

  onOpenDrawer();

  searchPlace({ use_distance: searchQuery.value.length > 0 });
}
function updateIcons() {
  const elMapIcon = document.getElementsByClassName("gm-style-mtc")[0];
  const elGeolocationIcon = document.getElementsByClassName(
    "custom-map-control-button"
  )[0];

  if (elMapIcon && elMapIcon.firstChild) {
    elMapIcon.firstChild.innerHTML = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1991_63721)">
            <path d="M1.66797 4.16669L7.5013 1.66669L12.5013 4.16669L17.7538 1.91585C17.8172 1.88867 17.8864 1.87766 17.9551 1.88381C18.0238 1.88995 18.0899 1.91306 18.1475 1.95106C18.2051 1.98906 18.2523 2.04076 18.285 2.10152C18.3176 2.16228 18.3347 2.2302 18.3346 2.29919V15.8334L12.5013 18.3334L7.5013 15.8334L2.2488 18.0842C2.1854 18.1114 2.11623 18.1224 2.04752 18.1162C1.97881 18.1101 1.9127 18.087 1.85512 18.049C1.79755 18.011 1.75031 17.9593 1.71764 17.8985C1.68498 17.8378 1.66791 17.7698 1.66797 17.7009V4.16669ZM12.5013 16.47V5.98002L12.4471 6.00335L7.5013 3.53002V14.02L7.55547 13.9967L12.5013 16.47Z" fill="#747474"/>
            </g>
            <defs>
            <clipPath id="clip0_1991_63721">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
            </svg>`;
  }
  if (elGeolocationIcon) {
    elGeolocationIcon.classList.add("active");
    elGeolocationIcon.innerHTML = `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.619741 6.76249C0.184741 6.61749 0.180575 6.38333 0.628075 6.23416L16.5339 0.932493C16.9747 0.785826 17.2272 1.03249 17.1039 1.46416L12.5589 17.3692C12.4339 17.81 12.1797 17.825 11.9931 17.4067L8.99807 10.6667L13.9981 3.99999L7.33141 8.99999L0.619741 6.76249Z" fill="#747474"/>
              </svg>`;
  }
}
function reportWindowSize(e) {
  if (window.innerWidth >= 1023) {
    state.isMobileDrawer = false;
    isMobile.value = false;
  } else {
    isMobile.value = true;
  }
  setTimeout(() => {
    updateIcons();
  }, 200);
}

function activeTabMethod(currentTab) {
  activetab.value = currentTab;
  distanceUnit.value = activetab.value;
  if (!searchQuery.value) {
    isEmpty.value = true;
  } else {
    isEmpty.value = false;
  }
  searchPlace({ use_distance: searchQuery.value.length > 0 });
}
function toggleService(serviceKey) {
  const existsIndex = services.value.indexOf(serviceKey);
  if (existsIndex === -1) {
    services.value.push(serviceKey);
  } else {
    services.value.splice(existsIndex, 1);
  }

  searchPlace({ use_distance: searchQuery.value.length > 0 });
}
function setCompanyCenter() {
  // Infinite budget for Googe Maps API?
  const hasInfiniteBudget = "🤣";
  if (hasInfiniteBudget === "🤣" && mainStore.currentSite.country.country_lat) {
    place = {
      lat: parseFloat(mainStore.currentSite.country.country_lat),
      lng: parseFloat(mainStore.currentSite.country.country_long),
    };
  } else {
    // money-wise guy.
    // setDefaultMapCenter(6);
  }
  searchPlace({ use_distance: false });
}
function isGeoEnabled() {
  if (!import.meta.browser) {
    return false;
  }

  return "geolocation" in navigator;
}

function getCurrentPosition(initial = false) {
  if (!isGeoEnabled()) {
    setCompanyCenter();
    return;
  }

  navigator.geolocation.getCurrentPosition(
    (position) => {
      const geocoder = new window.google.maps.Geocoder();
      return geocoder.geocode(
        {
          location: new window.google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          ),
        },
        (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK && !initial) {
            place = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            searchPlace();
            searchQuery.value = results[0].formatted_address;
            // console.log(results[0].formatted_address)
          } else {
            place = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            searchPlace({ use_distance: false });
          }
        }
      );
    },
    (error) => {
      if (error.code === error.PERMISSION_DENIED) {
        setCompanyCenter();
      }
      if (initial) {
        setCompanyCenter();
      }
    },
    {
      timeout: 70000,
      enableHighAccuracy: true,
      maximumAge: 75000,
    }
  );
}

function setDefaultMapCenter(zoom = 5) {
  map.setCenter({
    lat: parseFloat(mainStore.currentSite.country.country_lat),
    lng: parseFloat(mainStore.currentSite.country.country_long),
  });

  map.setZoom(zoom);
}

function setMapCenter(placeData, zoom = 6) {
  const position = new window.google.maps.LatLng(
    parseFloat(placeData.lat),
    parseFloat(placeData.lng)
  );
  if (!isNaN(position.lat()) || !isNaN(position.lng())) {
    map.setCenter(position);
  } else {
    map.setCenter({
      lat: parseFloat("41.8719"),
      lng: parseFloat("12.5674"),
    });
  }
  map.setZoom(zoom);
}

async function addMarker(data) {
  const position = new window.google.maps.LatLng(
    parseFloat(data.position.lat),
    parseFloat(data.position.lng)
  );

  if (isNaN(position.lat()) || isNaN(position.lng())) {
    console.warn("☢️ wrong position");
    console.table(data);
    return;
  }

  const glyphImg = document.createElement("img");

  glyphImg.src = "/ellipse.png";
  glyphImg.width = "18";
  glyphImg.height = "18";

  // add marker
  const marker = new window.google.maps.marker.AdvancedMarkerElement({
    position,
    map: map,
    content: glyphImg,
    zIndex: Number(google.maps.Marker.MAX_ZINDEX),
    // icon: {
    //   url: EllipseIcon,
    //   scaledSize: new window.google.maps.Size(18, 18),
    // },
  });

  bounds.value.extend(marker.position);

  const StoreInfoWindowInstance = createStoreInfoWindow(data);

  const infoWindow = new window.google.maps.InfoWindow({
    content: StoreInfoWindowInstance,
  });

  markers.value.push(marker);
  infoWindows.value.push(infoWindow);

  marker.addListener("click", () => {
    closeInfoWindows();
    infoWindow.open(map, marker);
    setMapCenter(marker.position, 15);
  });
}
const context = getCurrentInstance()?.appContext;

function createStoreInfoWindow(data) {
  let el = null;

  let vNode = createVNode(StoreInfoWindow, {
    distance: data.distance,
    info: data.info,
    position: data.position,
    showDetail: true,
  });

  if (context) vNode.appContext = context;
  if (typeof document !== "undefined")
    render(vNode, (el = document.createElement("div")));

  return vNode.el;
}

function closeInfoWindows() {
  infoWindows.value.forEach((info, index) =>
    info.close(map, markers.value[index])
  );
}

function goToMarker(index) {
  if (index === selectedStore.value) {
    return;
  }

  closeInfoWindows();
  state.isMobileDrawer = false;
  infoWindows.value[index].open(map, markers.value[index]);
  setMapCenter(markers.value[index].position, 15);

  selectedStore.value = index;
}

function resetData() {
  // reset bounds
  bounds.value = new window.google.maps.LatLngBounds();

  // clear infowindow
  closeInfoWindows();
  infoWindows.value = [];

  if (markerClustererData.value) {
    markerClustererData.value.clearMarkers();
  }

  // clear markers
  markers.value.forEach((marker, index) => marker.setMap(null));
  markers.value = [];

  // reset stores
  stores.value = [];
  selectedStore.value = null;
}

function resetState() {
  searchQuery.value = "";
  setCompanyCenter();
  searchPlace();
}
function closeSuggestions() {
  isSuggestionsOpen.value = false;
}

async function searchPlace(params) {
  if (isFetching.value) return false;
  resetData();
  try {
    isLoading.value = true;
    isFetching.value = true;

    const res = await nuxtApp.$api("/api/front/stores/services");
    if (res) {
      servicesList.value = res.data;
    }

    const { data } = await nuxtApp.$api("/api/front/stores/v2", {
      query: {
        distance: distance.value,
        distance_unit: distanceUnit.value,
        lat: place.lat,
        lng: place.lng,
        "services[]": services.value,
        use_distance: true,
        "distributor_categories[]": state.distributorCategories,
        ...params,
      },
    });

    stores.value = data;

    // setMapCenter(place);
    stores.value.forEach((store) => setTimeout(() => addMarker(store), 5));
    loading.value = false;
    setTimeout(() => {
      isLoading.value = false;
      isFetching.value = false;
      if (markers.value.length > 0) {
        map.fitBounds(bounds.value);

        // use default algorithm and renderer
        // markerClustererData.value = new MarkerClusterer({ markers: markers.value, map: map })
        const renderer = {
          render({ count, position }, stats, map) {
            // change color if this cluster has more markers than the mean cluster
            const color =
              count >= Math.max(10, stats.clusters.markers.mean)
                ? "#228f61"
                : "#13B973";
            // create svg literal with fill color
            const svg = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="60" height="60">
<circle cx="120" cy="120" opacity=".6" r="70" />
<circle cx="120" cy="120" opacity=".3" r="90" />
<circle cx="120" cy="120" opacity=".2" r="110" />


<text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-size="50" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${count}</text>
</svg>`;
            const title = `Cluster of ${count} markers`,
              // adjust zIndex to be above other markers
              zIndex = Number(google.maps.Marker.MAX_ZINDEX) + count;

            const parser = new DOMParser();

            const pinSvg = parser.parseFromString(
              svg,
              "image/svg+xml"
            ).documentElement;

            const clusterOptions = {
              position,
              zIndex,
              title,
              content: pinSvg,
            };

            return new google.maps.marker.AdvancedMarkerElement(clusterOptions);
          },
        };
        markerClustererData.value = new MarkerClusterer.MarkerClusterer({
          markers: markers.value,
          map: map,
          renderer: renderer,
        });
      }

      if (place.lat) {
        setMapCenter(place, 8);
      }
    }, 10);
  } catch (error) {
    console.log(error);
    isLoading.value = false;
    isFetching.value = false;
    loading.value = false;
    console.error(error);
  }
}
</script>
<style>
#map,
.display-sidebar {
  height: calc(100vh - 84px);
}

.card-scrollebar {
  height: calc(100vh - 265px);
}
.gmnoprint {
  right: 41px !important;
}
.gm-control-active[aria-label="Zoom out"] {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}
.gm-control-active.gm-fullscreen-control {
  @apply rounded-[5px] !important;
}
.gm-style-mtc button {
  border-radius: 5px !important;
}
.gm-style-mtc {
  margin-right: 15px !important;
  top: 2px !important;
}
.gm-svpc {
  @apply flex justify-center items-center rounded-[5px] top-[18px] !important;
}
.gm-bundled-control .gmnoprint > div {
  @apply rounded !important;
}

.gm-bundled-control {
  bottom: 190px !important;
}

[dir="rtl"] .gm-bundled-control {
  right: -40px !important;
}

.custom-map-control-button.active {
  background: white;
  padding: 10px;
  border-radius: 4px;
  bottom: 220px !important;
  right: 12px !important;
}
.gm-style-iw .gm-ui-hover-effect {
  @apply w-[36px] h-[43px] !important;
}
.gm-style-iw .gm-ui-hover-effect span {
  @apply w-[26px] h-[26px] !important;
}

.gm-style-iw.gm-style-iw-c {
  filter: drop-shadow(-5px 10px 15px rgba(52, 64, 68, 0.25)) !important;
  @apply rounded-[15px]  !max-h-[max-content] shadow-none !max-w-[max-content] p-0 !important;
}
.gm-style-iw-d {
  margin-top: -44px;
}
.gm-style-iw .gm-ui-hover-effect span {
  mask-image: url("/close.png");
  margin: 6px !important;
  height: 20px !important;
  width: 20px !important;
}
@media (max-width: 1023px) {
  .card-scrollebar {
    @apply h-full;
  }
  #map {
    @apply h-full;
  }
  .display-sidebar {
    @apply h-[222px];
  }
  .gm-style-mtc {
    margin-bottom: 20px !important;
  }
  .gm-control-active.gm-fullscreen-control {
    margin-bottom: 28px !important;
  }
  .display-sidebar.active {
    height: calc(100vh - 110px);
    @apply fixed;
  }
}
@media (max-width: 767px) {
  .display-sidebar {
    @apply h-[180px];
  }

  .gm-style-mtc button {
    right: 50px !important;
  }
  .custom-map-control-button.active {
    bottom: 29px !important;
  }
  .gm-control-active.gm-fullscreen-control {
    margin-right: 65px !important;
  }
  .display-sidebar.active {
    height: calc(100vh - 21vh);
    @apply fixed;
  }
  .card-scrollebar {
    height: calc(100vh - 230px);
  }
  .closemobile {
    bottom: calc(100vh - 17vh);
  }
}
.gm-bundled-control .gmnoprint > div {
  border-radius: 5px !important;
  overflow: hidden;
}
</style>
